$colorBrand: #d63333;
$colorTextPrimary: #000;
$colorTextSecondary: #222;

$colorBase: #fafafa;
$colorMuted: #6c757d;

$colorPrimary: $colorBrand;
$colorAccent: #00bcd4;

$colorScrollbarTrack: darken($colorPrimary, 10%);
$colorScrollbarThumb: $colorBase;

$btnColors: "default" $colorTextPrimary, "primary" $colorPrimary,
    "accent" $colorAccent;
$brandColors: (
    "archlinux" #1793d1,
    "bitcoin" #ff9900,
    "codeberg" #2185d0,
    "github" #555,
    // looks better than real brand color #24292e
    "gitlab" #fc6d26,
    "ko-fi" #29abe0,
    "liberapay" #f6c915,
    "linkedin" #0077b5,
    "paypal" #003087,
    "xing" #026466
);

$colorBaseDark: #1a1a1a;
$colorTextPrimaryDark: #fafafa;

$longShadowLength: 200px;

$gridColumns: 12;
$responsiveThresholds: (
    tiny: 576px,
    smol: 768px,
    med: 992px,
    big: 1300px,
);

@mixin main-font {
    font-family: "Fira Sans", "Helvetica Neue", "Arial", sans-serif;
}

@mixin alt-font {
    font-family: "Josefin Sans", "Fira Sans", "Helvetica Neue", "Arial",
        sans-serif;
}
