noscript {
  text-align: center;
  z-index: 1000;
  background-color: #fafafa;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (prefers-color-scheme: dark) {
  noscript {
    background-color: #1a1a1a;
  }
}

.wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}

body.scroll {
  padding-bottom: 0;
}

.sheet {
  opacity: 1;
  animation: none;
  top: initial !important;
  display: block !important;
  position: relative !important;
  transform: none !important;
}

img[data-src], .scroll-indicator {
  display: none;
}

/*# sourceMappingURL=index.d2b5f092.css.map */
